<template>
  <div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="filter_data"
      :rtl="false"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: ข้อมูลที่อยู่ใบกำกับภาษี -->
        <div v-if="props.column.field === 'info'" class="text-nowrap">
          <a
            @click="
              () => {
                if (props.row.booking && props.row.booking.length > 0) {
                  $router.push(
                    '/member/view/' + props.row.booking[0].member_id
                  );
                } else {
                  // Handle the case where props.row.booking is empty or undefined
                  // For example, you can display an error message or redirect to a different page.
                  console.error('No booking information available.');
                }
              }
            "
          >
            <h5>
              <u>{{ props.row.customer_name }}</u>
            </h5>
          </a>
          <h5>{{ truncateText(props.row.billing_address, 35) }}</h5>
        </div>

        <!-- Column: รายการ -->
        <span v-else-if="props.column.field === 'booking'">
          <div v-for="x in props.row.items" :key="x._id">
            - {{ x.item_name }}
          </div>
        </span>

        <span v-else-if="props.column.field === 'card_id'">
          {{ props.row.card_id }}
        </span>

        <!-- Column: invoice_id -->
        <span v-else-if="props.column.field === 'invoice_id'">
          {{ props.row.tax_invoice_no }} <br />
          <b-badge v-if="props.row.cancel_status" variant="danger">
            ใบกำกับภาษียกเลิก
          </b-badge>
          <b-badge v-if="props.row.is_delete" variant="danger">
            ใบกำกับภาษีถูกลบ
          </b-badge>
        </span>

        <!-- Column: ค่่าเช่า -->
        <span v-else-if="props.column.field === 'total_net_price'">
          {{ props.row.total_net_price }}฿
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" no-caret toggle-class="p-0" right>
              <template #button-content>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                >
                  รายละเอียด <feather-icon icon="ChevronDownIcon" />
                </b-button>
              </template>

              <b-dropdown-item @click="navigateToPrint(props.row._id)">
                <feather-icon icon="PrinterIcon" size="16" />
                <span class="align-middle ml-50">Print</span>
              </b-dropdown-item>

              <b-dropdown-item @click="navigateToEdit(props.row._id)">
                <feather-icon icon="EditIcon" size="16" />
                <span class="align-middle ml-50">แก้ไข</span>
              </b-dropdown-item>

              <b-dropdown-item @click="cancel_tax_invoice(props.row._id)">
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="align-middle ml-50">ยกเลิกใบกำกับภาษี</span>
              </b-dropdown-item>

              <hr />
              <b-dropdown-item
                :disabled="!deletAble(props.row)"
                @click="delete_tax_invoice(props.row._id)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="align-middle ml-50">ลบ</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from "@/store/index";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import { getUserRole } from "@/auth/utils";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  props: ["filter_data"],
  data() {
    return {
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: "Invoice ID",
          field: "invoice_id",
        },
        {
          label: "ข้อมูล",
          field: "info",
          width: "150px",
        },
        {
          label: "เลขประจำตัวผู้เสียภาษี",
          field: "card_id",
        },
        {
          label: "อุปกรณ์ที่เช่า",
          field: "booking",
        },
        {
          label: "ค่าเช่า",
          field: "total_net_price",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: this.okok,
      searchTerm: "",
    };
  },
  created() {
    // console.log("filter_data in ", filter_data);
    this.ROLE = getUserRole();
  },
  methods: {
    deletAble(x) {
      const today = new Date();
      const createdDate = new Date(x.createdAt);

      if (this.ROLE === "owner") {
        return true;
      } else {
        if (
          today.getDate() === createdDate.getDate() &&
          today.getMonth() === createdDate.getMonth() &&
          today.getFullYear() === createdDate.getFullYear()
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    navigateToEdit(invoice_id) {
      window.open("/tax-invoice/edit/" + invoice_id, "_blank");
    },
    navigateToPrint(invoice_id) {
      window.open("/tax-invoice/print/" + invoice_id, "_blank");
    },
    truncateText(text, maxLength) {
      if (text) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + "...";
        }
        return text;
      }
    },
    cancel_tax_invoice(id) {
      this.$bvModal
        .msgBoxConfirm("ยืนยันการยกเลิก !!!", {
          title: "ยืนยันการยกเลิก?",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("cancel", id);
          }
        });
    },
    delete_tax_invoice(id) {
      this.$bvModal
        .msgBoxConfirm("ยืนยัน ลบ เอกสาร !!!", {
          title: "ยืนยัน ลบ เอกสาร?",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "POST",
              url: `/tax-invoice/delete`,
              data: { invoice_id: id },
            }).then((x) => {
              this.$emit("reload");
            });
          }
        });
    },
  },
  computed: {
    okok() {
      return this.filter_data;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.badge {
  margin-top: 0.5rem;
}
</style>
