<template>
  <div>
    <b-card>
      <b-row>
        <!-- Order ID -->
        <b-col>
          <b-form-group label="Year Month" label-for="Input1">
            <b-form-input
              v-model="form.search.yearmonth"
              placeholder="ปีเดือน"
              autocomplete="off"
              @keyup.enter="search()"
            />
          </b-form-group>
        </b-col>
        <!-- อุปกรณ์ที่เช่า -->
        <b-col>
          <b-form-group label="Invoice ID" label-for="Input2">
            <b-form-input
              v-model="form.search.tax_invoice_no"
              placeholder="เลขที่ใบกำกับภาษี"
              autocomplete="off"
              @keyup.enter="search()"
            />
          </b-form-group>
        </b-col>
        <!-- ข้อมูลลูกค้า -->
        <b-col>
          <b-form-group label="ชื่อ" label-for="Input3">
            <b-form-input
              v-model="form.search.customer_name"
              placeholder="ชื่อบริษัท"
              autocomplete="off"
              @keyup.enter="search()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex align-items-center mb-2">
            <div>
              <span class="mb-0 mr-2">ใบกำกับภาษีถูกยกเลิก</span>
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                v-model="form.search.cancel_status"
                @change="search()"
              />
            </div>
            <div>
              <span class="mb-0 mr-2">ใบกำกับภาษีถูกลบ</span>
              <b-form-checkbox
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
                v-model="form.search.is_delete"
                @change="search()"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="text-right">
        <b-button variant="success" @click="search">Search</b-button>
      </div>
    </b-card>
    <b-card>
      <div class="text-right">
        <b-button
          variant="warning"
          class="mb-2 mr-1"
          @click="month_summary()"
          v-if="ROLE === 'owner'"
        >
          สรุปเดือน
        </b-button>
        <b-button variant="success" class="mb-2" @click="create_new()">
          <feather-icon icon="PlusIcon" />
          ออกใบกำกับภาษี
        </b-button>
      </div>
      <filter-table
        @cancel="cancel_tax_invoice"
        @reload="search"
        :filter_data="filter_data"
      />
    </b-card>
  </div>
</template>
<script>
import FilterTable from "./component/FilterTable.vue";
import { getUserRole } from "@/auth/utils";

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    FilterTable,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
  },
  created() {
    this.search();
    this.ROLE = getUserRole();
  },
  data() {
    return {
      ROLE: null,
      filter_data: [],
      form: {
        search: {
          order_id: null,
          equipment_name: null,
          customer_info: null,
          yearmonth: this.getYearMonth(),
          is_delete: false,
          cancel_status: false,
        },
      },
    };
  },
  methods: {
    create_new() {
      window.open("/tax-invoice/create/x", "_blank");
    },

    month_summary() {
      window.open("/tax-invoice/month-summary", "_blank");
    },
    filtered_data(x) {
      this.filter_data = x.filter_data;
    },
    search() {
      console.log("this.form", this.form);
      this.$http({
        method: "POST",
        url: `/tax-invoice/list/filter`,
        data: this.form,
      }).then((x) => {
        this.filter_data = x.data.data;
      });
    },
    cancel_tax_invoice(id) {
      this.$http({
        method: "POST",
        url: `/tax-invoice/cancel`,
        data: { invoice_id: id },
      }).then((x) => {
        console.log("invoice status updateed ", x.data.data);
      });
    },
    clear() {
      this.form.search.order_id = null;
      this.form.search.equipment_name = null;
      this.form.search.customer_info = null;
      this.search();
    },
    getYearMonth() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because months are zero-based

      return `${year}${month}`;
    },
  },
};
</script>
